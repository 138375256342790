import { NgModule } from "@angular/core";
import { APP_INITIALIZER } from '@angular/core';
import { YandexMetrikaService } from "./yandex-metrika.service";
import { VkRtrgPxService } from "./vk-rtrg-px.service";
import { GoogleTagManagerService } from "./google-tag-manager.service";

@NgModule({
    imports: [],
    providers: [
        YandexMetrikaService,
        {
            provide: APP_INITIALIZER,
            useFactory: (yaMetrikaService: YandexMetrikaService) => {
                return () => yaMetrikaService.init();
            },
            deps: [YandexMetrikaService],
            multi: true
        },
        VkRtrgPxService,
        {
            provide: APP_INITIALIZER,
            useFactory: (vkRtrgPxService: VkRtrgPxService) => {
                return () => vkRtrgPxService.init();
            },
            deps: [VkRtrgPxService],
            multi: true
        },
        GoogleTagManagerService,
        {
            provide: APP_INITIALIZER,
            useFactory: (googleTagManagerService: GoogleTagManagerService) => {
                return () => googleTagManagerService.init();
            },
            deps: [GoogleTagManagerService],
            multi: true
        }
    ],
    exports: []
})
export class AnalyticsModule {
}
