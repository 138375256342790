import { Component, OnInit, Optional } from "@angular/core";
import { Title } from "@angular/platform-browser";
import './error404.component.scss';
import { HttpResponseService } from "app/bootstrap/http-response.service";

@Component({
    selector: 'error404',
    templateUrl: 'error404.component.html'
})
export class Error404Component implements OnInit {

    public constructor(
        @Optional() protected httpResponseService: HttpResponseService,
        protected titleService: Title
    ) {
    }

    public ngOnInit(): void {
        if (this.httpResponseService) {
            this.httpResponseService.setStatusCode(404);
        }
        this.titleService.setTitle('Страница не найдена | Лайк Хостел');
    }

}
