<div *ngIf="footerService.backgroundImage" class="footer-bg" [attr.style]="getBackgroundStyle()"></div>
<div *ngIf="footerService.backgroundImage" class="footer-shadow"></div>
<div class="container pt-4">
    <div class="row">
        <div class="            col-md-4 order-md-first col-sm-6  order-sm-first col-12 order-first">
            <h4>Присоединяйтесь!</h4>
            <p class="footer-social mt-2 mt-sm-4">
                <a *ngIf="footerService.links.vkontakte" href="{{ footerService.links.vkontakte }}">
                    <i class="fab fa-vk"></i>
                </a>
                <a *ngIf="footerService.links.instagram" href="{{ footerService.links.instagram }}">
                    <i class="fab fa-instagram"></i>
                </a>
                <a *ngIf="footerService.links.facebook" href="{{ footerService.links.facebook }}">
                    <i class="fab fa-facebook-f"></i>
                </a>
                <a *ngIf="footerService.links.twitter" href="{{ footerService.links.twitter }}">
                    <i class="fab fa-twitter"></i>
                </a>
            </p>
        </div>
        <div class="text-center col-md-4 order-md-0     col-sm-12 order-sm-last  col-12 order-last">
            <h4>Сеть хостелов Like</h4>
            <p class="mt-2 mt-sm-4 mb-0">
                <a href="https://likehostels.ru" class="likehostel-logo"></a>
            </p>
            <p class="mt-2 mt-sm-4 mb-5 mb-sm-4">
                <a href="https://likehostels.ru/franshiza/">Стань франчайзи</a>
            </p>
        </div>
        <div *ngIf="!footerService.phone"
             class="text-right  col-md-4 order-md-last  col-sm-6  order-sm-6     col-12 order-6">
            <div class="footer-subscribe">
                <h4>Будьте в курсе!</h4>
                <p *ngIf="subscribeForm.controls.state.value == 'submitted'" class="mt-2 mt-sm-4">Подписка оформлена!</p>
                <p *ngIf="subscribeForm.controls.state.value != 'submitted'" class="mt-2 mt-sm-4">Узнавайте первым о выгодных предложениях и акциях!</p>
                <form *ngIf="subscribeForm.controls.state.value != 'submitted'" [formGroup]="subscribeForm" (ngSubmit)="onSubscribeFormSubmit()">
                    <div class="input-group mb-3">
                        <input type="email" formControlName="email"
                               class="form-control" placeholder="Введите Ваш e-mail">
                        <div class="input-group-append">
                            <input type="submit" value="OK" class="btn btn-like"
                                   [disabled]="!subscribeForm.valid || subscribeForm.controls.state.value == 'submitting'">
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div *ngIf="footerService.phone"
             class="text-right  col-md-4 order-md-last  col-sm-6  order-sm-6     col-12 order-6">
            <h4>Остались вопросы?</h4>
            <h4>Звоните!</h4>
            <p class="footer-phone">{{ footerService.phone }}</p>
            <button type="button" (click)="footerService.callme.emit()"
                    class="btn btn-like mb-3">Дорого звонить?
            </button>
        </div>
        <div class="likehostel-copyright">
            <span>Лайк Хостел © 2014-2018</span>
            <span>Все права защищены.</span>
        </div>
    </div>
    <cities-list *ngIf="footerService.showCitiesList"></cities-list>
</div>
