import { NgModule } from "@angular/core";
import { VacanciesComponent, VacanciesResolver } from "./vacancies.component";
import { VacancyComponent, VacancyResolver } from "./vacancy.component";
import { RouterModule, Routes } from "@angular/router";

@NgModule({
    imports: [RouterModule.forChild([])],
    providers: [
        VacanciesResolver, VacancyResolver
    ],
    exports: [RouterModule]
})
export class VacancyRoutingModule {
}

const likeHostelRoutes: Routes = [
    {
        path: 'job',
        component: VacanciesComponent,
        resolve: {
            component: VacanciesResolver
        }
    },
    {
        path: 'job/company/:companyId',
        component: VacanciesComponent,
        resolve: {
            component: VacanciesResolver
        }
    },
    {
        path: 'job/city/:alias',
        component: VacanciesComponent,
        resolve: {
            component: VacanciesResolver
        }
    },
    {
        path: 'job/hotel/:alias',
        component: VacanciesComponent,
        resolve: {
            component: VacanciesResolver
        }
    },
    {
        path: 'job/vacancy/:vacancyId',
        component: VacancyComponent,
        resolve: {
            component: VacancyResolver
        }
    }
];

@NgModule({
    imports: [VacancyRoutingModule, RouterModule.forChild(likeHostelRoutes)],
    exports: [RouterModule]
})
export class VacancyLikeHostelRoutingModule {
}

const hotelRoutes: Routes = [];

@NgModule({
    imports: [VacancyRoutingModule, RouterModule.forChild(hotelRoutes)],
    exports: [RouterModule]
})
export class VacancyHotelRoutingModule {
}
