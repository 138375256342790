import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { CityDescriptionComponent } from "./city-description/city-description.component";
import { CitySearchComponent } from "./city-search/city-search.component";
import { PopularCitiesComponent } from "./popular-cities/popular-cities.component";
import { CitiesListComponent } from "./cities-list/cities-list.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule
    ],
    declarations: [
        PopularCitiesComponent,
        CitySearchComponent,
        CityDescriptionComponent,
        CitiesListComponent
    ],
    exports: [
        PopularCitiesComponent,
        CitySearchComponent,
        CityDescriptionComponent,
        CitiesListComponent
    ]
})
export class CityCommonModule {
}
