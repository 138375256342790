<div class="container mt-5">
    <h1 class="text-center">ПОЛИТИКА ЗАЩИТЫ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ САЙТА</h1>
    <h4>1. Общие условия</h4>
    <p>
        1.1. Настоящая Политика определяет порядок обработки и защиты Обществом с ограниченной
        ответственностью «Лайк Бизнес» и ее аффилированных лиц информации о физических лицах (далее –
        Пользователи), которая может быть получена ООО «Лайк Бизнес» при использовании Пользователем услуг,
        предоставляемых посредством сайта, сервисов, служб, программ ООО «Лайк Бизнес» (далее – Сайт,
        Сервисы).
    </p>
    <p>
        1.2. Целью настоящей Политики является обеспечение надлежащей защиты информации о Пользователях, в
        том числе их персональных данных, от несанкционированного доступа и разглашения.
    </p>
    <p>
        1.3. Отношения, связанные со сбором, хранением, распространением и защитой информации предоставляемой
        Пользователем, регулируются настоящей Политикой, иными официальными документами ООО «Лайк Бизнес» и
        действующим законодательством Российской Федерации.
    </p>
    <p>
        1.4. Регистрируясь на Сайте и используя Сайт и Сервисы Пользователь выражает свое полное согласие с
        условиями настоящей Политики.
    </p>
    <p>
        1.5. В случае несогласия Пользователя с условиями настоящей Политики использование Сайта и/или
        каких-либо Сервисов доступных при использован ии Сайта должно быть немедленно прекращено.
    </p>

    <h4>2. Цели сбора, обработки и хранения информации предоставляемой пользователями Сайта</h4>
    <p>
        2.1. Обработка персональных данных Пользователя осуществляется в соотв етствии с законодательством
        Российской Федерации. ООО «Лайк Бизнес обрабатывает персональные данные Пользователя в целях:
        - идентификации стороны в рамках соглашений и договоров с ООО «Лайк Бизнес»;
        - предоставления Пользователю услуг, в том числе, в целях получения Пользователем таргетированной
        рекламы;
        - проверки, исследования и анализа таких данных, позволяющих поддерживать и улучшать сервисы и разделы
        Сайта, а также разрабатывать новые сервисы и разделы Сайта.
        - проведение статистических и иных исследований, на основе обезличенных данных.
    </p>
    <h4>3. Условия обработки персональной информации предоставленной Пользователем и ее передачи третьим лицам </h4>
    <p>
        3.1. ООО «Лайк Бизнес» принимает все необходимые меры для защиты персональных данных Пользователя от
        неправомерного доступа, изменения,
        раскрытия или уничтожения.
    </p>
    <p>
        3.2. ООО «Лайк Бизнес» предоставляет доступ к персональным данным Пользователя только тем работникам,
        подрядчикам и аффилированным лицам которым эта информация необходима для обеспечения функционирования
        Сайта, Сервисов и оказания Услуг Пользователю.
    </p>
    <p>
        3.3. ООО «Лайк Бизнес» вправе использовать предоставленную Пользователем информацию, в том числе
        персональные данные, в целях обеспечения соблюдения требований действующего законодательства Российской
        Федерации
        (в том числе в целях предупреждения и/или пресечения незаконных и/или противоправных действий
        Пользователей). Раскрытие предоставленной Пользователем информации может быть произведено лишь в
        соответствии с действующим законодательством Российской Федерации по требованию суда,
        правоохранительных органов, а равно в иных предусмотренных законодательством Российской Федерации
        случаях.
    </p>
    <h4>4. Условия пользования Сайтом, Сервисами</h4>
    <p>
        4.1. Пользователь при пользовании Сайтом, подтверждает, что:
        обладает всеми необходимыми правами, позволяющими ему осуществлять регистрацию (создание учетной записи)
        и использовать Услуги сайта; указывает достоверную информацию о себе в объемах, необходимых для
        пользования Услугами Сайта, обязательные для заполнения поля для дальнейшего предоставления Услуг сайта
        помечены специальным образом, вся иная информация предоставляется пользователем по его собственному
        усмотрению. осознает, что информация на Сайте, размещаемая Пользователем о себе, может становиться
        доступной для третьих лиц, неоговоренных в настоящей Политике и может быть скопирована и распространена
        ими;
        ознакомлен с настоящей Политикой, выражает свое согласие с ней и принимает на себя указанные в ней права
        и обязанности. Ознакомление с условиями настоящей Политики и проставление галочки под ссылкой на данную
        Политику является письменным согласием Пользователя на сбор, хранение, обработку и передачу третьим
        лицам персональных данных предоставляемых Пользователем.
    </p>
    <p>
        4.2. ООО «Лайк Бизнес» не проверяет достоверность получаемой (собираемой) информации о Пользователях, за
        исключением случаев, когда такая проверка необходима в целях исполнения обязательств перед
        пользователем.
    </p>
    <h4>5. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:</h4>
    <p>
        5.1. Данные предоставленные Пользователем самостоятельно при пользовании Сайтом, Сервисами включая, но,
        не ограничиваясь: имя, фамилия, пол, номер мобильного телефона и/или адрес электронной почты, семейное
        положение,
        дата рождения, родной город, родственные связи, домашний адрес, информация об образовании, о роде
        деятельности.
    </p>
    <p>
        5.2. Данные, которые автоматически передаются Сервисам в процессе их использования с помощью
        установленного на устройстве Пользователя
        программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере пользователя
        (или иной программе, с помощью которой осуществляется доступ к Сервисам), время доступа, адрес
        запрашиваемой страницы.
    </p>
    <p>
        5.3 Иная информация о Пользователе, сбор и/или предоставление которой определено в Регулирующих
        документах отдельных Сервисов ООО «Лайк Бизнес».
    </p>
    <h4>6. Изменение и удаление персональных данных</h4>
    <p>
        6.1. Пользователь может в любой момент изменить (обновить, дополнить)
        предоставленную им персональную информацию или её часть, а также параметры её конфиденциальности,
        воспользовавшись функцией редактирования персональных данных в разделе, либо в персональном разделе
        соответствующего Сервиса.
    </p>
    <p>
        6.2. Пользователь также может удалить предоставленную им в рамках определенной учетной записи
        персональную информацию. При этом удаление аккаунта может повлечь невозможность использования некоторых
        Сервисов.
    </p>
    <h4>7. Изменение Политики конфиденциальности. Применимое законодательство</h4>
    <p>
        7.1. ООО «Лайк Бизнес» имеет право вносить изменения в настоящую Политику конфиденциальности. При
        внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики
        вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.
    </p>
    <p>
        7.2. К настоящей Политике и отношениям между Пользователем и ООО
        «Лайк Бизнес» возникающим в связи с применением Политики конфиденци альности, подлежит применению право
        Российской Федерации.
    </p>
    <h4>8. Обратная связь. Вопросы и предложения</h4>
    <p>
        8.1. Все предложения или вопросы по поводу настоящей Политики следует сообщать в Службу поддержки ООО
        «Лайк Бизнес» по телефону <b>8-800-333-87-01</b> или <b>+7(499)649-40-24</b>
    </p>
</div>
