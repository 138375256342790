import { FormControl, FormGroup, Validators } from "@angular/forms";
import { VacancyResponse } from "./vacancy";

export class VacancyResponseForm extends FormGroup {

    public constructor() {
        super({
            name: new FormControl(null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            phone: new FormControl(null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            email: new FormControl(null, [
                Validators.email,
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            coveringLetter: new FormControl(null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(3000)
            ])
        });
    }

    public getResponse(): VacancyResponse {
        let response = new VacancyResponse();
        response.name = this.get('name').value;
        response.phone = (this.get('phone').value as string).trim().replace(/\D/g, ''); // @todo: fix it
        response.email = this.get('email').value;
        response.coveringLetter = this.get('coveringLetter').value;
        return response;
    }

}
