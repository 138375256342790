import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Injectable()
export class TitleService {

    public constructor(
        protected ngTitle: Title
    ) {
    }

}
