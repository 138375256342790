import { Component, Injectable, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Vacancy, VacancyResponse } from "./vacancy";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { BootstrapService } from "app/bootstrap/bootstrap.service";
import { HttpClient } from "@angular/common/http";
import { TransferStateService } from "app/bootstrap/transfer-state.service";
import { map } from "rxjs/operators";
import './vacancy.component.scss';
import { Title } from "@angular/platform-browser";
import { VacancyResponseForm } from "./vacancy-response.form";

export class VacancyData {
    @JsonProperty({type: Vacancy})
    public vacancy: Vacancy;
}

@Injectable()
export class VacancyResolver implements Resolve<VacancyData> {
    protected readonly TRANSFER_STATE_KEY = 'VacancyResolverData';

    public constructor(
        protected bootstrapService: BootstrapService,
        protected httpClient: HttpClient,
        protected transfaerStateService: TransferStateService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<VacancyData> {
        return this.transfaerStateService.get<VacancyData>(this.TRANSFER_STATE_KEY, () => {
            return this.httpClient.get<VacancyData>('/v2/vacancies/' + route.params.vacancyId)
                .pipe(map(data => ObjectMapper.deserialize(VacancyData, data)))
                .toPromise();
        });
    }

}

export class VacancyResponseRequest {
    public constructor(
        @JsonProperty({type: VacancyResponse})
        public response: VacancyResponse
    ) {
    }
}

@Component({
    selector: 'vacancy-vacancy',
    templateUrl: './vacancy.component.html'
})
export class VacancyComponent implements OnInit {
    public vacancy: Vacancy;
    public responseForm: VacancyResponseForm = new VacancyResponseForm();
    public showResponseMsg: boolean = false;

    public constructor(
        protected route: ActivatedRoute,
        protected title: Title,
        protected httpClient: HttpClient
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.vacancy = data.component.vacancy;
            this.title.setTitle('Вакансия ' + this.vacancy.title
                + ' в г. ' + this.vacancy.city.name
                + ', работа в ' + (this.vacancy.hotel ? this.vacancy.hotel.name : this.vacancy.company.name)
            );
        });
    }

    public onResponseSubmit(): void {
        this.responseForm.disable();
        this.httpClient.post(
            '/v2/vacancies/' + this.vacancy.vacancyId + '/response',
            ObjectMapper.serialize(new VacancyResponseRequest(this.responseForm.getResponse()))
        ).toPromise().then(data => {
            this.showResponseMsg = true;
        }).catch(error => {
            console.error('Send vacancy response error:', error);
            this.responseForm.enable();
            throw error;
        });
    }

}
