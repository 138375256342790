import { NgModule } from "@angular/core";
import { CommonModule as NgCommonModule } from "@angular/common";
import { Nl2BrPipe } from "./nl2br.pipe";
import { MaxlengthPipe } from "./maxlength.pipe";
import './common.scss'
import { DatepickerDirective } from "./datepicker.directive";
import { MarkdownRenderComponent } from "./markdown-render.component";

@NgModule({
    imports: [NgCommonModule],
    providers: [
        Nl2BrPipe
    ],
    declarations: [
        Nl2BrPipe,
        MaxlengthPipe,
        DatepickerDirective,
        MarkdownRenderComponent
    ],
    exports: [
        Nl2BrPipe,
        MaxlengthPipe,
        DatepickerDirective,
        MarkdownRenderComponent
    ]
})
export class CommonModule {
}
