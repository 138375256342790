import { APP_INITIALIZER, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import './layout.scss';
import { MiniNavbarComponent } from "./navbar/mini-navbar.component";
import { CityCommonModule } from "city/city-common.module";
import { HostelNavbarComponent } from "./navbar/hostel-navbar.component";
import { NavbarService } from "./navbar/navbar.service";
import { AnchorLinkService } from "./anchor-link.service";
import { LayoutCommonModule } from "./layout-common.module";
import { ProgressbarComponent } from "./progressbar/progressbar.component";
import { ProgressbarService } from "./progressbar/progressbar.service";
import { ProgressbarRoutingService } from "./progressbar/progressbar-routing.service";
import { LikehostelNavbarComponent } from "./navbar/likehostel-navbar.component";

@NgModule({
    imports: [
        CommonModule,
        LayoutCommonModule,
        RouterModule,
        CityCommonModule
    ],
    providers: [
        NavbarService,
        AnchorLinkService,
        {
            provide: APP_INITIALIZER,
            useFactory: (anchorLinkService: AnchorLinkService) => {
                return () => anchorLinkService.init();
            },
            deps: [AnchorLinkService],
            multi: true
        },
        ProgressbarService,
        ProgressbarRoutingService,
        {
            provide: APP_INITIALIZER,
            useFactory: (progressbarRoutingService: ProgressbarRoutingService) => {
                return () => progressbarRoutingService.init();
            },
            deps: [ProgressbarRoutingService],
            multi: true
        }
    ],
    declarations: [
        MiniNavbarComponent, HostelNavbarComponent, LikehostelNavbarComponent,
        ProgressbarComponent
    ],
    exports: [
        LayoutCommonModule,
        MiniNavbarComponent, HostelNavbarComponent, LikehostelNavbarComponent,
        ProgressbarComponent
    ]
})
export class LayoutModule {
}
