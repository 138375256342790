import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { PrivacyComponent } from "./privacy/privacy.component";
import { RulesComponent } from "./rules/rules.component";

@NgModule({
    imports: [RouterModule.forChild([
        {path: 'privacy', component: PrivacyComponent},
        {path: 'rules', component: RulesComponent}
    ])],
    exports: [RouterModule]
})
export class DocumentsRoutingModule {
}
