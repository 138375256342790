import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'likehostel-privacy',
    templateUrl: 'privacy.component.html'
})
export class PrivacyComponent implements OnInit {

    public constructor(
        protected titleService: Title
    ) {
    }

    public ngOnInit(): void {
        this.titleService.setTitle('Политика защиты персональной информации пользователей сайта | Лайк Хостел');
    }

}
