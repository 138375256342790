import { Inject, Injectable, NgZone, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from "@angular/common";
import { BootstrapData, BootstrapService } from "app/bootstrap/bootstrap.service";
import { GlobalConfig } from "app/bootstrap/global-config";

@Injectable()
export class YandexMetrikaService {

    protected bootstrapData: BootstrapData;

    protected counters: Map<number, any> = new Map<number, any>();

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        @Inject(DOCUMENT) protected document: HTMLDocument,
        protected ngZone: NgZone,
        protected globalConfig: GlobalConfig,
        protected bootstrapService: BootstrapService
    ) {
    }

    public init(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.bootstrapService.getData().then((data) => {
                this.bootstrapData = data;
                if (this.globalConfig.isProductionMode() && this.bootstrapData.analytics.yandexMetrika) {
                    let hasMetrikaVersion1 = false;
                    let hasMetrikaVersion2 = false;
                    for (let counter of this.bootstrapData.analytics.yandexMetrika.counters) {
                        if (!counter.version || counter.version == 1) {
                            hasMetrikaVersion1 = true;
                        } else if (counter.version == 2) {
                            hasMetrikaVersion2 = true;
                        }
                    }

                    if (isPlatformServer(this.platformId)) {
                        if (hasMetrikaVersion1) {
                            this.resolveScript();
                        }
                        if (hasMetrikaVersion2) {
                            this.resolveScript2();
                        }
                        this.initMetrika();
                    }
                    if (isPlatformBrowser(this.platformId)) {
                        let resolveScripts: Promise<void>[] = [];
                        if (hasMetrikaVersion1 && (typeof Ya != 'object' || typeof Ya.Metrika != 'function')) {
                            let resolveScript = new Promise<void>((resolve) => {
                                this.resolveScript().addEventListener('load', () => {
                                    resolve();
                                });
                            });
                            resolveScripts.push(resolveScript);
                        }
                        if (hasMetrikaVersion2 && (typeof Ya != 'object' || typeof Ya.Metrika2 != 'function')) {
                            let resolveScript = new Promise<void>((resolve) => {
                                this.resolveScript2().addEventListener('load', () => {
                                    resolve();
                                });
                            });
                            resolveScripts.push(resolveScript);
                        }

                        if (hasMetrikaVersion1 || hasMetrikaVersion2) {
                            Promise.all(resolveScripts).then(() => {
                                this.initMetrika();
                            });
                        }
                    }
                }
                resolve();
            });
        });
    }

    protected resolveScript(): HTMLScriptElement {
        let src = 'https://mc.yandex.ru/metrika/watch.js';
        let script: HTMLScriptElement = null;
        let docScripts = this.document.getElementsByTagName('script');
        for (let i = docScripts.length; i--;) {
            if (docScripts[i].src == src) {
                script = docScripts[i];
                break;
            }
        }
        if (!script) {
            script = this.document.createElement('script');
            script.async = true;
            script.src = src;
            script.type = 'text/javascript';
            this.document.body.appendChild(script);
        }
        return script;
    }

    protected resolveScript2(): HTMLScriptElement {
        let src = 'https://mc.yandex.ru/metrika/tag.js';
        let script: HTMLScriptElement = null;
        let docScripts = this.document.getElementsByTagName('script');
        for (let i = docScripts.length; i--;) {
            if (docScripts[i].src == src) {
                script = docScripts[i];
                break;
            }
        }
        if (!script) {
            script = this.document.createElement('script');
            script.async = true;
            script.src = src;
            script.type = 'text/javascript';
            this.document.body.appendChild(script);
        }
        return script;
    }

    protected resolveImg(counterId: number): HTMLImageElement {
        let src = 'https://mc.yandex.ru/watch/' + counterId.toString();
        let img: HTMLImageElement = null;
        let docImgs = this.document.getElementsByTagName('img');
        for (let i = docImgs.length; i--;) {
            if (docImgs[i].src == src) {
                img = docImgs[i];
                break;
            }
        }
        if (!img) {
            img = this.document.createElement('img');
            let noscript = this.document.createElement('noscript');
            img.setAttribute('style', 'position:absolute; left:-9999px;');
            img.src = src;
            img.alt = '';
            noscript.appendChild(img);
            this.document.body.appendChild(noscript);
        }
        return img;
    }

    protected initMetrika(): void {
        for (let counter of this.bootstrapData.analytics.yandexMetrika.counters) {
            if (isPlatformServer(this.platformId)) {
                this.resolveImg(counter.id);
            }
            if (isPlatformBrowser(this.platformId)) {
                let settings = counter.settings;
                if (!settings) settings = {};
                settings.id = counter.id;
                let version: number = counter.version ? counter.version : 1;
                this.ngZone.runOutsideAngular(() => {
                    switch (version) {
                        case 1:
                            this.counters.set(counter.id, new Ya.Metrika(settings));
                            break;
                        case 2:
                            this.counters.set(counter.id, new Ya.Metrika2(settings));
                            break;
                        default:
                            throw new Error("Unknown Yandex.Metrika version");
                    }
                });
            }
        }
    }

    public getClientId(counterId?: number): number {
        if (counterId) {
            if (!this.counters.has(counterId)) {
                return null;
            }
            return this.counters.get(counterId).getClientID();
        }

        for (let counter of this.counters.values()) {
            return counter.getClientID();
        }
        return null;
    }

}
