import { NgModule } from "@angular/core";
import { PrivacyComponent } from "./privacy/privacy.component";
import { DocumentsRoutingModule } from "./documents-routing.module";
import { RulesComponent } from "./rules/rules.component";

@NgModule({
    imports: [
        DocumentsRoutingModule
    ],
    declarations: [
        PrivacyComponent,
        RulesComponent
    ],
    exports: []
})
export class DocumentsModule {
}
