import { Component, OnInit } from "@angular/core";
import { GlobalConfig } from "app/bootstrap/global-config";
import './cities-list.component.scss';
import { BootstrapData, City } from "app/bootstrap/bootstrap.service";

@Component({
    selector: 'cities-list',
    templateUrl: './cities-list.component.html'
})
export class CitiesListComponent implements OnInit {
    protected cities: Map<string, City[]> = new Map<string, City[]>();

    public constructor(
        protected globalConfig: GlobalConfig,
        protected bootstrapData: BootstrapData
    ) {
    }

    public ngOnInit(): void {
        for (let city of this.bootstrapData.cities) {
            let letter = city.name.substr(0, 1).toUpperCase();
            if (!this.cities.has(letter)) this.cities.set(letter, []);
            this.cities.get(letter).push(city);
        }
    }

    public getCities(letter?: string): City[] {
        if (letter) {
            return this.cities.get(letter);
        }
        return this.bootstrapData.cities;
    }

    public getColumns(): string[][] {
        let citiesLength = Math.round(this.getCities().length / 4);
        let columnLength = 0;
        let columns: string[][] = [];
        let column = 0;
        for (let key of this.cities.keys()) {
            let keyLength = this.getCities(key).length;
            if (columnLength + keyLength > citiesLength && column < 3) {
                columnLength = 0;
                column++;
            }
            if (columns.length - 1 < column) columns[column] = [];
            columns[column].push(key);
            columnLength += keyLength;
        }
        return columns;
    }

    public getLetters(column: number): string[] {
        return this.getColumns()[column];
    }

    public getCityUrl(alias: string): string {
        // @todo: temporary transition url. Fix in next major release
        //return this.globalConfig.deploy.scheme + '://' + alias + '.' + this.globalConfig.deploy.domain;
        return 'http://' + alias + '.' + this.globalConfig.deploy.domain;
    }

    public trackByCities(index: number, city: City): number {
        return city.cityId;
    }

}
