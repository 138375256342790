import { Component, Input } from "@angular/core";
import './whats-in.component.scss';

@Component({
    selector: 'hostel-whats-in',
    templateUrl: './whats-in.component.html'
})
export class WhatsInComponent {

    @Input('title')
    public title: string = 'Что в хостеле?';

}
