import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'nl2br', pure: false})
@Injectable()
export class Nl2BrPipe implements PipeTransform {

    public transform(value: string, ...args: any[]): string {
        return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

}
