import { EventEmitter, Injectable } from "@angular/core";
import { ActivationEnd, Event as RouterEvent, Router } from "@angular/router";
import { merge } from 'lodash';

export interface FooterLinks {
    vkontakte?: string;
    instagram?: string;
    facebook?: string;
    twitter?: string;
}

export interface FooterOptions {
    showInLayout: boolean;
    showCitiesList: boolean;
    backgroundImage?: string;
    links: FooterLinks;
    phone?: string;
}

@Injectable()
export class FooterService implements FooterOptions {

    protected defaultOptions: FooterOptions = {
        showInLayout: true,
        showCitiesList: true,
        backgroundImage: null,
        links: {
            vkontakte: 'https://vk.com/public79059773',
            instagram: 'https://www.instagram.com/likehostelclub/'
        },
        phone: null
    };

    protected options: FooterOptions = this.getDefaultOptions();

    public readonly callme: EventEmitter<void> = new EventEmitter<void>();

    public constructor(
        protected router: Router
    ) {
        this.init();
    }

    public init(): void {
        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof ActivationEnd) {
                this.setOptions(merge({}, this.getDefaultOptions(), event.snapshot.data.footer));
            }
        });
    }

    public setDefaultOptions(options: FooterOptions) {
        this.defaultOptions = options;
    }

    public getDefaultOptions(): FooterOptions {
        return this.defaultOptions;
    }

    public setOptions(options: FooterOptions): void {
        this.options = options;
    }

    public getOptions(): FooterOptions {
        return this.options;
    }

    public get showInLayout(): boolean {
        return this.options.showInLayout;
    }

    public set showInLayout(show: boolean) {
        this.options.showInLayout = show;
    }


    public get showCitiesList(): boolean {
        return this.options.showCitiesList;
    }

    public set showCitiesList(show: boolean) {
        this.options.showCitiesList = show;
    }

    public get backgroundImage(): string {
        return this.options.backgroundImage;
    }

    public set backgroundImage(backgroundImage: string) {
        this.options.backgroundImage = backgroundImage;
    }

    public get links(): FooterLinks {
        return this.options.links;
    }

    public set links(links: FooterLinks) {
        this.options.links = links;
    }

    public get phone(): string {
        return this.options.phone;
    }

    public set phone(phone: string) {
        this.options.phone = phone;
    }

}
