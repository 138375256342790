import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { GlobalConfig } from "./global-config";

@Injectable()
export class WebapiHttpInterceptor implements HttpInterceptor {

    public constructor(protected globalConfig: GlobalConfig) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith('http')) {
            return next.handle(req);
        }
        req = req.clone({
            url: this.globalConfig.webapi.url + req.url
        });
        return next.handle(req);
    }
}
