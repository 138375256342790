import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from "@angular/common";
import { BootstrapService } from "app/bootstrap/bootstrap.service";
import { GlobalConfig } from "app/bootstrap/global-config";
import { BootstrapData } from "app/bootstrap/bootstrap.service";

@Injectable()
export class GoogleTagManagerService {

    protected bootstrapData: BootstrapData;

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        @Inject(DOCUMENT) protected document: HTMLDocument,
        protected globalConfig: GlobalConfig,
        protected bootstrapService: BootstrapService
    ) {
    }

    public init(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.bootstrapService.getData().then((data) => {
                this.bootstrapData = data;
                if (this.globalConfig.isProductionMode() && this.bootstrapData.analytics.googleTagManager) {
                    if (isPlatformBrowser(this.platformId)) {
                        this.initGtm();
                    }
                    if (isPlatformServer(this.platformId)) {
                        this.resolveIFrame();
                    }
                    this.resolveScript();
                }
            });
            resolve();
        });
    }

    protected initGtm(): void {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    }

    protected resolveScript(): HTMLScriptElement {
        let src = 'https://www.googletagmanager.com/gtm.js?id=' + this.bootstrapData.analytics.googleTagManager.containerId;
        let script: HTMLScriptElement = null;
        let docScripts = this.document.getElementsByTagName('script');
        for (let i = docScripts.length; i--;) {
            if (docScripts[i].src == src) {
                script = docScripts[i];
                break;
            }
        }
        if (!script) {
            script = this.document.createElement('script');
            script.async = true;
            script.src = src;
            script.type = 'text/javascript';
            this.document.body.appendChild(script);
        }
        return script;
    }

    protected resolveIFrame(): HTMLIFrameElement {
        let src = 'https://www.googletagmanager.com/ns.html?id=' + this.bootstrapData.analytics.googleTagManager.containerId;
        let iframe: HTMLIFrameElement = null;
        let docIframes = this.document.getElementsByTagName('iframe');
        for (let i = docIframes.length; i--;) {
            if (docIframes[i].src == src) {
                iframe = docIframes[i];
                break;
            }
        }
        if (!iframe) {
            iframe = this.document.createElement('iframe');
            iframe.src = src;
            iframe.width = '0';
            iframe.height = '0';
            iframe.setAttribute('style', 'display:none;visibility:hidden');
            let noscript = this.document.createElement('noscript');
            noscript.appendChild(iframe);
            this.document.body.appendChild(noscript);
        }
        return iframe;
    }

}
