import { CacheKey, Deserializer, Serializer } from "json-object-mapper";

@CacheKey("DateSerializerDeserializer")
export class DateSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: number): Date {
        return new Date(value * 1000);
    }

    public serialize(value: Date): number {
        return value.getTime() / 1000;
    }

}
