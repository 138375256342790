import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable()
export class MetaService {

    public constructor(
        @Inject(DOCUMENT) protected document: HTMLDocument
    ) {
    }

    public setDescription(description: string): MetaService {
        this.resolveMetaTag('description').content = description;
        return this;
    }

    public setKeywords(keywords: string): MetaService {
        this.resolveMetaTag('keywords').content = keywords;
        return this;
    }

    public getDescription(): string {
        return this.resolveMetaTag('description').content;
    }

    public getKeywords(): string {
        return this.resolveMetaTag('keywords').content;
    }

    protected resolveMetaTag(name: string): HTMLMetaElement {
        let tag: HTMLMetaElement = null;
        let metaTags = this.document.getElementsByTagName('meta');
        for (let i = metaTags.length; i--;) {
            if (metaTags[i].name == name) {
                tag = metaTags[i];
                break;
            }
        }
        if (!tag) {
            tag = this.document.createElement('meta');
            tag.name = name;
            this.document.head.appendChild(tag);
        }
        return tag;
    }

}
