import { AccessType, JsonProperty } from "json-object-mapper";

export class Hotel {

    @JsonProperty({name: 'hotel_id', access: AccessType.READ_ONLY})
    public readonly hotelId: number;

    @JsonProperty({name: 'name', access: AccessType.READ_ONLY})
    public readonly name: number;

}
