import { Component } from "@angular/core";
import './progressbar.component.scss';
import { ProgressbarService } from "./progressbar.service";

@Component({
    selector: 'progressbar',
    templateUrl: './progressbar.component.html'
})
export class ProgressbarComponent {

    public constructor(
        public progressbarService: ProgressbarService
    ) {
    }

}
