import { NgModule } from "@angular/core";
import { VacanciesComponent } from "./vacancies.component";
import { VacancyComponent } from "./vacancy.component";
import { CommonModule as AppCommonModule } from "common/common.module";
import { CommonModule } from "@angular/common";
import {
    VacancyHotelRoutingModule,
    VacancyLikeHostelRoutingModule,
    VacancyRoutingModule
} from "./vacancy-routing.module";
import { VacancyMiniComponent } from "./vacancy-mini.component";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    imports: [
        CommonModule, AppCommonModule,
        VacancyRoutingModule,
        ReactiveFormsModule
    ],
    providers: [],
    declarations: [
        VacanciesComponent, VacancyComponent,
        VacancyMiniComponent
    ]
})
export class VacancyModule {
}

@NgModule({
    imports: [VacancyModule, VacancyLikeHostelRoutingModule]
})
export class VacancyLikeHostelModule {
}

@NgModule({
    imports: [VacancyModule, VacancyHotelRoutingModule]
})
export class VacancyHotelModule {
}
