import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WhatsInComponent } from "./whats-in/whats-in.component";
import { ReviewComponent } from "./review/review.component";
import { ReviewsListComponent } from "./review/reviews-list.component";
import { CommonModule as AppCommonModule } from 'common/common.module';
import { AddressComponent } from "./index/address.component";

@NgModule({
    imports: [
        CommonModule,
        AppCommonModule
    ],
    declarations: [
        WhatsInComponent,
        ReviewComponent, ReviewsListComponent,
        AddressComponent
    ],
    exports: [
        WhatsInComponent,
        ReviewComponent, ReviewsListComponent,
        AddressComponent
    ]
})
export class HostelCommonModule {
}
