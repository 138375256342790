<h4 class="mb-3">Куда Вы хотите отправиться?</h4>
<form [formGroup]="searchForm" (ngSubmit)="onSearchFormSubmit()">
    <div class="form-group row">
        <div class="col-8">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" [class.border-bottom-0]="isShowDropdown()">
                        <i class="fas fa-location-arrow"></i>
                    </span>
                </div>
                <input type="text" class="form-control"
                       [class.border-bottom-0]="isShowDropdown()"
                       formControlName="search"
                       (focusin)="searchFocus($event)"
                       (focusout)="searchFocus($event)"
                       #search
                       placeholder="Например: Санкт-Петербург">
            </div>
            <div class="dropdown-menu border-top-0" [class.show]="isShowDropdown()" #dropdown>
                <a class="dropdown-item" *ngFor="let city of getFoundCities(); trackBy: trackByCities"
                   href="{{ getCityUrl(city.alias) }}"
                   [attr.data-city-id]="city.cityId"
                   (mouseenter)="dropdownMenuMouseEnter($event)">
                    <i class="fas fa-map-marker-alt"></i>{{ city.name }}
                </a>
            </div>
        </div>
        <div class="col-4">
            <button type="submit" class="btn btn-block btn-like">Найти</button>
        </div>
    </div>
</form>
