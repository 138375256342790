import { Component } from "@angular/core";
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { FooterService } from "./footer.service";
import './footer.component.scss';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent {

    public subscribeForm = new FormGroup({
        email: new FormControl(null, [
            Validators.required,
            Validators.minLength(5),
            Validators.email
        ]),
        state: new FormControl()
    });

    public constructor(
        protected domSanitizer: DomSanitizer,
        protected httpClient: HttpClient,
        public footerService: FooterService
    ) {
    }

    public getBackgroundStyle(): SafeStyle {
        if (this.footerService.backgroundImage) {
            return this.domSanitizer.bypassSecurityTrustStyle('background-image: url("' + this.footerService.backgroundImage + '")');
        } else {
            return this.domSanitizer.bypassSecurityTrustStyle('background-image: none');
        }
    }

    public onSubscribeFormSubmit(): void {
        this.subscribeForm.get('state').setValue('submitting');
        this.httpClient.post('/v1/feedback/subscribe', {
            email: this.subscribeForm.get('email').value
        }).toPromise()
            .then(() => {
                this.subscribeForm.get('state').setValue('submitted');
            })
            .catch((error: Error) => {
                console.error('Subscribe error:', error);
                this.subscribeForm.get('state').setValue(null);
            });
    }

}
