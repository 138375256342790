import { AccessType, JsonProperty } from "json-object-mapper";

export class City {

    @JsonProperty()
    public readonly id: number;

    @JsonProperty()
    public readonly alias: string;

    @JsonProperty()
    public readonly name: string;

    @JsonProperty()
    public readonly description: string;

    @JsonProperty()
    public readonly img: string;

}

export class City2 {

    @JsonProperty({name: 'vacancy_id', access: AccessType.READ_ONLY})
    public readonly cityId: number;

    @JsonProperty({access: AccessType.READ_ONLY})
    public readonly alias: string;

    @JsonProperty({access: AccessType.READ_ONLY})
    public readonly name: string;

}
