import { APP_INITIALIZER, NgModule } from "@angular/core";
import { JivoSiteService } from "./jivosite.service";

@NgModule({
    imports: [],
    providers: [
        JivoSiteService,
        {
            provide: APP_INITIALIZER,
            useFactory: (jivoSiteService: JivoSiteService) => {
                return () => jivoSiteService.init();
            },
            deps: [JivoSiteService],
            multi: true
        }
    ]
})
export class FeedbackModule {
}
