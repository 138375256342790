import { Component, OnInit } from "@angular/core";
import { GlobalConfig } from "app/bootstrap/global-config";
import './popular-cities.component.scss';
import { BootstrapData, City } from "app/bootstrap/bootstrap.service";

@Component({
    selector: 'popular-cities',
    templateUrl: './popular-cities.component.html'
})
export class PopularCitiesComponent implements OnInit {
    protected cities: City[] = [];

    public constructor(
        protected globalConfig: GlobalConfig,
        protected bootstrapData: BootstrapData
    ) {
    }

    public ngOnInit(): void {
        this.cities = this.bootstrapData.cities.filter(city => city.popular.popular);
    }

    public getCities(): City[] {
        return this.cities;
    }

    public trackByCities(index: number, city: City): number {
        return city.cityId;
    }

    public getCityUrl(alias: string): string {
        // @todo: temporary transition url. Fix in next major release
        //return this.globalConfig.deploy.scheme + '://' + alias + '.' + this.globalConfig.deploy.domain;
        return 'http://' + alias + '.' + this.globalConfig.deploy.domain;
    }

    public getCityImg(city: City): string {
        return this.globalConfig.content.url + city.popular.img;
    }

}
