import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { GlobalConfig } from "app/bootstrap/global-config";
import { BootstrapService } from "app/bootstrap/bootstrap.service";
import { BootstrapData } from "app/bootstrap/bootstrap.service";

@Injectable()
export class VkRtrgPxService {
    protected bootstrapData: BootstrapData;

    public constructor(
        @Inject(DOCUMENT) protected document: HTMLDocument,
        protected globalConfig: GlobalConfig,
        protected bootstrapService: BootstrapService
    ) {
    }

    public init(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.bootstrapService.getData().then((data) => {
                this.bootstrapData = data;
                if (this.globalConfig.isProductionMode() && this.bootstrapData.analytics.vkRtrgPx) {
                    this.resolveImg();
                    resolve();
                } else {
                    resolve();
                }
            });
        });
    }

    protected resolveImg(): HTMLImageElement {
        let src = 'https://vk.com/rtrg?r=' + this.bootstrapData.analytics.vkRtrgPx.rtrg;
        let img: HTMLImageElement = null;
        let docImgs = this.document.getElementsByTagName('img');
        for (let i = docImgs.length; i--;) {
            if (docImgs[i].src == src) {
                img = docImgs[i];
                break;
            }
        }
        if (!img) {
            img = this.document.createElement('img');
            img.setAttribute('style', 'position:absolute; left:-9999px;');
            img.src = src;
            img.alt = '';
            this.document.body.appendChild(img);
        }
        return img;
    }

}
