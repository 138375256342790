import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TransferStateService } from "./transfer-state.service";
import { GlobalConfig } from "./global-config";
import { Deserializer, JsonProperty, ObjectMapper, Serializer } from "json-object-mapper";
import { map } from "rxjs/operators";

export enum BootstrapType {
    LIKEHOSTEL,
    HOSTEL,
    CITY,
    NOT_FOUND,
    DUMMY
}

export class BootstrapTypeSerializerDeserializer implements Deserializer, Serializer {
    deserialize = (value: string): BootstrapType => {
        switch (value) {
            case 'likehostel':
                return BootstrapType.LIKEHOSTEL;
            case 'city':
                return BootstrapType.CITY;
            case 'hostel':
                return BootstrapType.HOSTEL;
            case 'notfound':
                return BootstrapType.NOT_FOUND;
            case 'dummy':
                return BootstrapType.DUMMY;
            default:
                throw new Error('Unknown type "' + value + '"');
        }
    };
    serialize = (value: BootstrapType): string => {
        switch (value) {
            case BootstrapType.LIKEHOSTEL:
                return 'likehostel';
            case BootstrapType.CITY:
                return 'city';
            case BootstrapType.HOSTEL:
                return 'hostel';
            case BootstrapType.NOT_FOUND:
                return 'notfound';
            case BootstrapType.DUMMY:
                return 'dummy';
            default:
                throw new Error('Unknown type "' + value + '"');
        }
    };
}


export class CityPopular {
    @JsonProperty()
    public readonly popular: boolean;

    @JsonProperty()
    public readonly img?: boolean;
}

export class City {
    @JsonProperty()
    public readonly name: string;

    @JsonProperty({name: "city_id"})
    public readonly cityId: number;

    @JsonProperty()
    public readonly alias: string;

    @JsonProperty({type: CityPopular})
    public readonly popular: CityPopular;
}

export class AnalyticsYandexMetrikaCounter {
    @JsonProperty()
    public readonly id: number;

    @JsonProperty()
    public readonly version?: number;

    @JsonProperty({required: false})
    public readonly settings?: any;
}

export class AnalyticsYandexMetrika {

    @JsonProperty({type: AnalyticsYandexMetrikaCounter})
    public readonly counters: AnalyticsYandexMetrikaCounter[];

}

export class AnalyticsVkRtrgPx {

    @JsonProperty()
    public readonly rtrg: string;

}

export class AnalyticsGoogleConversion {

    @JsonProperty({name: 'conversion_id'})
    public readonly conversionId: string;

    @JsonProperty({name: 'remarketing_only'})
    public readonly remarketingOnly: boolean;

    @JsonProperty({name: 'conversion_label', required: false})
    public readonly conversionLabel?: string;

}

export class AnalyticsGoogleTagManager {
    @JsonProperty({name: 'container_id'})
    public readonly containerId: string;
}

export class Analytics {

    @JsonProperty({name: 'yandex_metrika', type: AnalyticsYandexMetrika, required: false})
    public readonly yandexMetrika?: AnalyticsYandexMetrika;

    @JsonProperty({name: 'vk_rtrg_px', type: AnalyticsVkRtrgPx, required: false})
    public readonly vkRtrgPx?: AnalyticsVkRtrgPx;

    @JsonProperty({name: 'google_conversion', type: AnalyticsGoogleConversion, required: false})
    public readonly googleConversion?: AnalyticsGoogleConversion;

    @JsonProperty({name: 'google_tag_manager', type: AnalyticsGoogleTagManager, required: false})
    public readonly googleTagManager?: AnalyticsGoogleTagManager;

}

export class BootstrapCityData {

    @JsonProperty({name: 'city_id'})
    public readonly cityId: number;

    @JsonProperty()
    public readonly alias: string;

    @JsonProperty()
    public readonly name: string;

    @JsonProperty()
    public readonly title: string;
}

export class BootstrapHostelLinksData {
    @JsonProperty({required: false})
    public readonly vkontakte?: string;

    @JsonProperty({required: false})
    public readonly instagram?: string;

    @JsonProperty({required: false})
    public readonly facebook?: string;

    @JsonProperty({required: false})
    public readonly twitter?: string;
}

export class BootstrapHostelBookingWubookData {
    @JsonProperty()
    public readonly code: string;
}

export class BootstrapHostelBookingBnovoData {
    @JsonProperty()
    public readonly uid: string;
}

export class BootstrapHostelBookingData {
    @JsonProperty()
    public readonly provider: string;

    @JsonProperty({required: false, type: BootstrapHostelBookingWubookData})
    public readonly wubook?: BootstrapHostelBookingWubookData;

    @JsonProperty({required: false, type: BootstrapHostelBookingBnovoData})
    public readonly bnovo?: BootstrapHostelBookingBnovoData;
}

export class BootstrapHostelData {

    @JsonProperty({name: 'hostel_id'})
    public readonly hostelId: number;

    @JsonProperty()
    public readonly alias: string;

    @JsonProperty()
    public readonly name: string;

    @JsonProperty()
    public readonly title: string;

    @JsonProperty()
    public readonly photo: string;

    @JsonProperty()
    public readonly phone: string;

    @JsonProperty()
    public readonly address: string;

    @JsonProperty({type: BootstrapHostelLinksData})
    public readonly links: BootstrapHostelLinksData;

    @JsonProperty({type: BootstrapHostelBookingData})
    public readonly booking: BootstrapHostelBookingData;
}

export class BootstrapData {

    @JsonProperty()
    public readonly version: string;

    @JsonProperty({
        type: BootstrapType,
        deserializer: BootstrapTypeSerializerDeserializer,
        serializer: BootstrapTypeSerializerDeserializer
    })
    public readonly type: BootstrapType;

    @JsonProperty({type: City})
    public readonly cities: City[] = [];

    @JsonProperty({type: BootstrapCityData, required: false})
    public readonly city?: BootstrapCityData;

    @JsonProperty({type: BootstrapHostelData, required: false})
    public readonly hostel?: BootstrapHostelData;

    @JsonProperty({type: Analytics})
    public readonly analytics: Analytics;

}

export abstract class AbstractBootstrapService {
    protected static readonly TRANSFER_STATE_KEY = 'BootstrapServiceData';
    private data: BootstrapData;
    private dataPromise: Promise<BootstrapData>;

    public constructor(
        protected globalConfig: GlobalConfig,
        protected transferStateService?: TransferStateService
    ) {
    }

    public setTransferStateService(transferStateService: TransferStateService): void {
        this.transferStateService = transferStateService;
        this.getData().then((data) => {
            this.transferStateService.set<BootstrapData>(AbstractBootstrapService.TRANSFER_STATE_KEY, data);
        });
    }

    public getData(): Promise<BootstrapData> {
        if (this.data) {
            return new Promise<BootstrapData>(resolve => resolve(this.data));
        }
        if (this.dataPromise) {
            return this.dataPromise;
        }
        if (this.transferStateService) {
            this.dataPromise = this.transferStateService.get<BootstrapData>(AbstractBootstrapService.TRANSFER_STATE_KEY, () => {
                return this.loadData();
            });
        } else {
            this.dataPromise = this.loadData();
        }
        return new Promise<BootstrapData>((resolve, reject) => {
            this.dataPromise.then((data) => {
                // @todo: hotfix
                let dummyAlias = 'kerch';
                if (data.type == BootstrapType.HOSTEL && data.hostel.alias == dummyAlias
                    || data.type == BootstrapType.CITY && data.city.alias == dummyAlias) {
                    (data as any).type = BootstrapType.DUMMY;
                }
                this.data = data;
                this.dataPromise = null;
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    protected abstract loadData(): Promise<BootstrapData>;

    protected getBootstrapUrl(): string {
        if (this.isSubdomain()) {
            return this.globalConfig.webapi.url + '/v1/bootstrap/' + this.getSubdomain();
        } else {
            return this.globalConfig.webapi.url + '/v1/bootstrap';
        }
    }

    public getType(): Promise<BootstrapType> {
        return new Promise<BootstrapType>((resolve, reject) => {
            this.getData().then((data) => {
                resolve(data.type);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getWebapiVersion(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.getData().then((data) => {
                resolve(data.version);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public abstract getSubdomain(): string | null;

    public isSubdomain(): boolean {
        return this.getSubdomain() != null;
    }

    protected getSubdomainFromHostname(hostname: string): string | null {
        let subdomain = hostname;
        if (subdomain.endsWith(this.globalConfig.deploy.domain)) {
            subdomain = subdomain.substr(0, subdomain.length - this.globalConfig.deploy.domain.length);
        }
        if (subdomain.endsWith('.')) {
            subdomain = subdomain.substr(0, subdomain.length - 1);
        }
        return (subdomain.length == 0) ? null : subdomain;
    }

}

@Injectable()
export class BootstrapService extends AbstractBootstrapService {

    public constructor(
        globalConfig: GlobalConfig,
        @Inject('http_hostname') protected httpHostname: string,
        protected httpClient: HttpClient,
        @Optional() transferStateService?: TransferStateService
    ) {
        super(globalConfig, transferStateService);
    }

    protected loadData(): Promise<BootstrapData> {
        return this.httpClient
            .get(this.getBootstrapUrl())
            .pipe(map(data => ObjectMapper.deserialize(BootstrapData, data)))
            .toPromise();
    }

    public getSubdomain(): string | null {
        return this.getSubdomainFromHostname(this.httpHostname);
    }

}
