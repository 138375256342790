import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

export class JsonHttpInterceptor implements HttpInterceptor {

    // @todo: remove API version check after migrate to API v2
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('Content-Type') && req.url.startsWith('/v2')) {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'application/json')
            });
        }
        return next.handle(req);
    }

}
