<h4 class="mb-3">Популярные направления</h4>
<div class="row">
    <div class="text-center col-4 col-sm-2 mb-3 mb-sm-0" *ngFor="let city of getCities(); trackBy: trackByCities">
        <a href="{{ getCityUrl(city.alias) }}">
            <img src="{{ getCityImg(city) }}"
                 alt="{{ city.name }}" class="img-spinner">
        </a>
        <a href="{{ getCityUrl(city.alias) }}" class="mt-2">{{ city.name }}</a>
    </div>
</div>
