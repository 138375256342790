import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BootstrapData, BootstrapService } from "./bootstrap.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { WebapiHttpInterceptor } from "./webapi.http-interceptor";
import { TransferStateService } from "./transfer-state.service";
import { TitleService } from "./title.service";
import { MetaService } from "./meta.service";
import { errorHandler } from "app/error/error";
import { JsonHttpInterceptor } from "./json.http-interceptor";

let bootstrapData: BootstrapData;

export function bootstrap(bootstrapService: BootstrapService) {
    return () => {
        return new Promise<void>((resolve) => {
            bootstrapService.getData().then((data) => {
                bootstrapData = data;
                resolve();
            });
        });
    };
}

export class AppErrorHandler implements ErrorHandler {

    public handleError(error: any): void {
        errorHandler(error);
    }

}

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        {provide: ErrorHandler, useClass: AppErrorHandler, deps: []},
        {provide: BootstrapService, useExisting: 'bootstrap-service'},
        TransferStateService,
        {provide: HTTP_INTERCEPTORS, useClass: JsonHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: WebapiHttpInterceptor, multi: true},
        {
            provide: APP_INITIALIZER,
            useFactory: bootstrap,
            deps: [BootstrapService],
            multi: true
        },
        {
            provide: BootstrapData,
            useFactory: () => {
                if (!bootstrapData) {
                    throw new Error('Bootstrap data is empty');
                }
                return bootstrapData;
            }
        },
        TitleService, MetaService
    ],
    exports: [
        HttpClientModule
    ]
})
export class BootstrapModule {
}
