import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { BootstrapService } from "app/bootstrap/bootstrap.service";

@Injectable()
export class JivoSiteService {
    protected widgetId: string = 'g3Ce60VIkv';

    public constructor(
        @Inject(DOCUMENT) protected document: HTMLDocument,
        protected bootstrapService: BootstrapService
    ) {
    }

    public init(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.bootstrapService.getData().then((data) => {
                if (data.hostel && data.hostel.alias == 'krasnodar') { // @todo: hardcode!
                    this.resolveScript();
                }
                resolve();
            });
        });
    }

    protected resolveScript(): HTMLScriptElement {
        let src = 'https://code.jivosite.com/script/widget/' + this.widgetId;
        let script: HTMLScriptElement = null;
        let docScripts = this.document.getElementsByTagName('script');
        for (let i = docScripts.length; i--;) {
            if (docScripts[i].src == src) {
                script = docScripts[i];
                break;
            }
        }
        if (!script) {
            script = this.document.createElement('script');
            script.async = true;
            script.src = src;
            script.type = 'text/javascript';
            this.document.body.appendChild(script);
        }
        return script;
    }

}
