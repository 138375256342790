import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'likehostel-rules',
    templateUrl: 'rules.component.html'
})
export class RulesComponent {

    public constructor(
        protected titleService: Title
    ) {
    }

    public ngOnInit(): void {
        this.titleService.setTitle('Правила | Лайк Хостел');
    }

}
