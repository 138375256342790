<div class="hostel-whats-in pt-5 pb-5">
    <h3 class="text-center mb-5">{{ title }}</h3>
    <div class="container">
        <div class="row">
            <div class="col-4 mb-3 col-md-2 mb-md-0">
                <i class="lh-whats-in-clean"></i>
                <p>Постоянная чистота</p>
            </div>
            <div class="col-4 mb-3 col-md-2 mb-md-0">
                <i class="lh-whats-in-parking"></i>
                <p>Бесплатная парковка</p>
            </div>
            <div class="col-4 mb-3 col-md-2 mb-md-0">
                <i class="lh-whats-in-kid"></i>
                <p>Размещение с детьми</p>
            </div>
            <div class="col-4 mb-3 col-md-2 mb-md-0">
                <i class="lh-whats-in-comfort"></i>
                <p>Домашний уют</p>
            </div>
            <div class="col-4 mb-3 col-md-2 mb-md-0">
                <i class="lh-whats-in-wifi"></i>
                <p>Бесплатные Wi-Fi и Xbox</p>
            </div>
            <div class="col-4 mb-3 col-md-2 mb-md-0">
                <i class="lh-whats-in-services"></i>
                <p>Утюг, стиралка и прочие удобства</p>
            </div>
        </div>
    </div>
</div>
