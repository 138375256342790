<div class="container mt-5">
    <h1 class="text-center mb-5">
        При бронировании спальных мест и вводе персональных данных на сайте likehostels.ru (в форме
        бронирования), пользователь соглашается со следующими условиями:
    </h1>

    <p>
        1. Пользователь дает согласие на обработку своих персональных данных ООО «Лайк Хостел»
        (ИНН 1841034063, ОГРН 1131841003832, адрес получения корреспонденции:
        123112, г. Москва, Пресненская набережная, д. 12, Федерация Восток, 30 этаж, офис 1),
        при этом пользователь подтверждает, что действует своей волей и в своих интересах.
    </p>
    <p>
        2. Настоящим пользователь подтверждает, что все передаваемые данные верны, указанный номер является
        номером телефона пользователя, выданным ему оператором сотовой связи, а также пользователь готов
        возместить любой ущерб, который может быть причинен в связи с указанием некорректных данных.
    </p>
    <p>
        3. Перечень персональных данных, на обработку которых пользователь дает свое согласие:<br>
        — Фамилия, имя, отчество пользователя;<br>
        — Контактный телефон;<br>
        — Адрес электронной почты;<br>
        — Паспортные данные или данные иных документов удостоверяющих личность;<br>
        — Город проживания;<br>
        — Пол;<br>
        — Адрес проживания;<br>
        — Дата заселения и выселения в Хостел.
    </p>
    <p>
        4. Перечень действий с персональными данными, на совершение которых дается согласие:<br>
        — Получение персональных данных у субъекта персональных данных;<br>
        — Хранение персональных данных (в электронном виде и на бумажном носителе);<br>
        — Уточнение (обновление, изменение) персональных данных;<br>
        — Совершение иных действий, предусмотренных законодательством РФ.
    </p>
    <p>
        5. ООО «Лайк Хостел» обязуется не передавать базы пользователей третьим лицам (за исключением
        случаев, предусмотренных законом, и только при наличии официального запроса от властей).
    </p>
    <p>
        6. Пользователь дает свое согласие на получение рекламной информации, информации об услугах ООО «Лайк Хостел», иной информации, связанной с деятельностью ООО «Лайк Хостел» по адресу электронной
        почты, указанной в форме бронирования.
    </p>
    <p>
        7. Порядок отзыва настоящего согласия – по личному письменному заявлению пользователя в адрес ООО «Лайк Хостел» не менее чем за 30 дней до отзыва такого согласия.
    </p>
    <p>
        8. Данные условия доступны по адресу <a href="https://likehostels.ru/rules">https://likehostels.ru/rules</a>
        и могут быть изменены или дополнены
        администрацией ООО «Лайк Хостел» в любое время. Пользователь обязуется самостоятельно проверять
        актуальность этих условий.
    </p>
    <p>
        9. Данные хранятся в защищенной базе данных.
    </p>

    <h4>Правила проживания в Like Hostel</h4>
    <p>
        <b>Расчётное время в Like Hostel:</b><br>
        — Время заселения — с 14:00;<br>
        — Время выезда — 12:00.
    </p>
    <p>
        <b>Место в Хостеле предоставляется:</b><br>
        — для граждан Российской Федерации при предъявлении:<br>
        — паспорта гражданина Российской Федерации;<br>
        — общегражданского заграничного паспорта;<br>
        — удостоверения личности военнослужащего;<br>
        — для офицеров Вооруженных Сил Российской Федерации;<br>
        — военного билета — для солдат, матросов, сержантов и старшин, проходящих службу по призыву или
        контакту;<br>
        — паспорта моряка — для граждан, работающих на российских судах заграничного плавания или на
        иностранных судах;
    </p>
    <p>
        — для иностранных граждан при предъявлении:<br>
        — паспорта иностранного гражданина либо иного документа, устанавливаемого федеральным законом или
        признаваемого в соответствии с международным договором Российской Федерации в качестве документа,
        удостоверяющего личность;<br>
        — миграционной карты, заполняемой при въезде в Российскую Федерацию (при наличии);<br>
        — действующей визы (при наличии).
    </p>
    <p>
        1. В Like Hostel запрещается распространение, хранение и употребление наркотических веществ и алкогольных
        напитков.
    </p>
    <p>
        2.Обязательно соблюдение тишины с 23:00 до 9:00!
    </p>
    <p>
        3.Категорически запрещается хранить деньги, ценные вещи и документы в комнатах. В случае необходимости
        Like Hostel предоставляет возможность сдать вещи на хранение администратору, либо в сейф. Услуга хранения
        - бесплатная.
    </p>
    <p>
        4. Администрация Like Hostel не несет ответственности за сохранность личных вещей, оставленных без
        присмотра на территории Хостела.
    </p>
    <p>
        5. Курить разрешается только в специально отведенном месте, запрещается курить в любых других помещениях
        Like Hostel.
    </p>
    <p>
        6. Коммуникации с персоналом Like Hostel и его клиентами производятся только в дружественной и деловой
        форме, на территории Хостела запрещаются повышение тона и любые формы ненормативной лексики.
    </p>
    <p>
        7. При отказе клиента от размещения менее 3 дней до заезда, ранее внесенная сумма не возвращается.
    </p>
    <p>
        8. За повреждение имущества Хостела взимается штраф в размере рыночной розничной стоимости имущества.
    </p>
    <p>
        9. Руководители, сопровождающие школьные группы, несут полную ответственность за поведение группы в
        Хостеле.
    </p>
    <p>
        10. Запрещается хранить в общих комнатах любые открытые продукты питания.
    </p>
    <p>
        11. В Like Hostel разрешается слушать музыку в наушниках, чтобы не доставлять неудобства другим гостям.
    </p>
    <p>
        12. В Like Hostel разрешается играть на музыкальных инструментах . Включать музыку разрешается только по
        согласованию с администрацией хостела.
    </p>
    <p>
        13. В обеспечение мер противопожарной безопасности, запрещается пользоваться личными нагревательными
        приборами. Уходя из номера, выключайте, пожалуйста: кондиционер, телевизор, лампы освещения и другие
        электроприборы, не оставляйте открытыми водозаборные краны.
    </p>
    <p>
        14. В нашем хостеле самообслуживание! Если Вы пользуетесь кухней, пожалуйста, мойте за собой посуду,
        убирайте со стола. То же относится к душевым кабинам и туалету. Мы просим уважительного отношения к
        человеку, зашедшему после Вас.
    </p>
    <p>
        15. Мы производим смену постельного белья каждые 7 дней. Уборка в номерах - по требованию.
    </p>
    <p>
        16. Время работы хостела с 7:00 до 23:00. В случае прихода гостя в ночные часы, когда хостел закрыт – штраф
        200 рублей с человека.
    </p>
    <p>
        18. В случае, если после 13-00 клиент не освободит занимаемое место, применяется почасовая система оплаты
        проживания - 50 рублей в час.
    </p>
    <p>
        19. Хостел предоставляет платные услуги, в том числе аренда стиральной машины – 50 рублей, аренда утюга и
        гладильной доски- 50 рублей.
    </p>
    <p>
        20. В целях обеспечения безопасности проживающих и охраны порядка, сотрудники Хостела в момент Вашего
        прибытия могут обратиться к Вам с вопросом о цели посещения Хостела, попросить Вас предъявить «Карту
        гостя» для подтверждения факта проживания в Хостеле.
    </p>
    <p>
        21. Запрещается приглашать в Хостел незнакомых людей.
    </p>
    <p>
        22. По окончании заселения гость вправе получить услуги, входящие в стоимость проживания:<br>
        – Чай<br>
        – Пользование оборудованием кухни, посудой<br>
        – Пользование душем, туалетом<br>
        – Комплект постельного белья + полотенце<br>
        – Пользование wi-fi интернетом<br>
        – Парковка во дворе Хостела<br>
        – Пользование сейфом
    </p>
    <p>
        23. За нарушение правил поведения в Хостеле администрация имеет право выселить нарушителей в любое
        время без возврата оплаты за проживание. Все споры, разногласия и требования, которые могут возникнуть
        между Сторонами, будут по возможности прекращаться путем переговоров и в соответствии с
        Законодательством РФ.
    </p>

    <p><b>Прочие условия:</b></p>
    <p>
        – Проживать в Хостеле без сопровождения взрослых имеют право гости старше 18 лет.
    </p>
    <p>
        – Гости Хостела, не достигшие 18 летнего возраста, должны иметь нотариально заверенную доверенность от
        обоих родителей, разрешающую их самостоятельное размещение в Хостеле.
    </p>
    <p>
        – Предоставление услуг Хостелом осуществляется при условии 100% оплаты.
    </p>
    <p>
        – Плата за проживание взимается при заезде. При задержке выезда плата взимается за следующие сутки.
    </p>
    <p>
        – Гости, проживающие в Хостеле, при заезде вносят залог в размере 400 рублей. При выезде из Хостела залог
        возвращается в полном объеме при соблюдении всех правил Хостела и возврате постельного белья.
    </p>
    <p>
        – Цена места в Хостеле, а также форма его оплаты устанавливаются администрацией Хостела.
    </p>
    <p>
        – Администрация Хостела оставляет за собой право при наличии свободных мест в комнате подселять гостей
        независимо от пола и возраста.
    </p>
    <p>
        – Иностранных граждан информируем, что Вы обязаны зарегистрировать свое пребывание на территории РФ в
        течение суток с момента пересечения границы. Like Hostel предоставляет услугу по оформлению регистрации в
        УФМС. Необходимые документы: паспорт, виза, миграционная карта.
    </p>
    <p class="mb-5"></p>
</div>
