<div class="row">
    <div class="col-3" *ngFor="let column of [0,1,2,3]">
        <ul class="cities-list" *ngFor="let letter of getLetters(column)">
            <li class="cities-list-letter">{{ letter }}</li>
            <li>
                <ul>
                    <li *ngFor="let city of getCities(letter); trackBy: trackByCities">
                        <a href="{{ getCityUrl(city.alias) }}">{{ city.name }}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
