import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import './city-search.component.scss';
import { GlobalConfig } from "app/bootstrap/global-config";
import { BootstrapData, City } from "app/bootstrap/bootstrap.service";

@Component({
    selector: 'city-search',
    templateUrl: './city-search.component.html'
})
export class CitySearchComponent implements OnInit {

    @ViewChild('dropdown')
    protected dropdown: ElementRef;

    @ViewChild('search')
    protected search: ElementRef;

    protected foundCities: City[] = [];
    protected searchHasFocus = false;

    public searchForm = new FormGroup({
        search: new FormControl('')
    });

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected globalConfig: GlobalConfig,
        protected bootstrapData: BootstrapData
    ) {
    }

    public ngOnInit(): void {
        this.searchForm.get('search').valueChanges.subscribe((value) => {
            jQuery(this.dropdown.nativeElement).find('.active').removeClass('active');
            value = value.trim();
            if (value.length < 2) {
                this.foundCities = [];
            } else {
                this.foundCities = this.getCities().filter((city) => {
                    return city.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
                });
            }
        });
    }

    public getCities(): City[] {
        return this.bootstrapData.cities;
    }

    public getCity(cityId: number): City | null {
        for (let city of this.getCities()) {
            if (city.cityId == cityId) {
                return city;
            }
        }
        return null;
    }

    @Input('focus')
    public set focus(focus: boolean) {
        if (isPlatformBrowser(this.platformId) && focus) {
            jQuery(this.search.nativeElement).trigger('focus');
        }
    }

    public getFoundCities(): City[] {
        return this.foundCities;
    }

    public trackByCities(index: number, city: City): number {
        return city.cityId;
    }

    public getCityUrl(alias: string): string {
        // @todo: temporary transition url. Fix in next major release
        //return this.globalConfig.deploy.scheme + '://' + alias + '.' + this.globalConfig.deploy.domain;
        return 'http://' + alias + '.' + this.globalConfig.deploy.domain;
    }

    public isShowDropdown(): boolean {
        return this.foundCities.length > 0 && this.searchHasFocus;
    }

    @HostListener('keydown.ArrowUp', ['$event'])
    public keydownArrowUp(event: KeyboardEvent): void {
        if (!this.isShowDropdown()) return;
        event.preventDefault();
        let dropdownElement = jQuery(this.dropdown.nativeElement);
        let current = dropdownElement.find('.active').get(0);
        dropdownElement.find('.active').removeClass('active');
        if (!current) {
            jQuery(dropdownElement.children().last().get(0)).addClass('active');
        } else {
            jQuery(current).prev().addClass('active');
        }
    }

    @HostListener('keydown.ArrowDown', ['$event'])
    public keydownArrowDown(event: KeyboardEvent): void {
        if (!this.isShowDropdown()) return;
        event.preventDefault();
        let dropdownElement = jQuery(this.dropdown.nativeElement);
        let current = dropdownElement.find('.active').get(0);
        dropdownElement.find('.active').removeClass('active');
        if (!current) {
            jQuery(dropdownElement.children().get(0)).addClass('active');
        } else {
            jQuery(current).next().addClass('active');
        }
    }

    @HostListener('keydown.enter', ['$event'])
    public keydownEnter(event: KeyboardEvent): void {
        if (!this.isShowDropdown()) return;
        let active = jQuery(this.dropdown.nativeElement).find('.active').get(0);
        if (active) {
            event.preventDefault();
            let city = this.getCity(jQuery(active).data('city-id'));
            window.location.href = this.getCityUrl(city.alias);
        }
    }

    @HostListener('keydown.esc', ['$event'])
    public keydownEsc(event: KeyboardEvent): void {
        if (!this.searchHasFocus) return;
        event.preventDefault();
        jQuery(this.search.nativeElement).trigger('blur');
    }

    public dropdownMenuMouseEnter(event: MouseEvent): void {
        jQuery(this.dropdown.nativeElement).find('.active').removeClass('active');
        jQuery(event.target).addClass('active');
    }

    public searchFocus(event: MouseEvent): void {
        if (event.type == 'focusin') {
            this.searchHasFocus = true;
        }
        if (event.type == 'focusout') {
            setTimeout(() => {
                this.searchHasFocus = false;
            }, 100);
        }
    }

    public onSearchFormSubmit(): void {
        let value = this.searchForm.get('search').value.trim().toLowerCase().replace(/[^a-zа-я]/g, '');
        for (let city of this.getCities()) {
            let name = city.name.trim().toLowerCase().replace(/[^a-zа-я]/g, '');
            if (name == value) {
                window.location.href = this.getCityUrl(city.alias);
                return;
            }
        }
        if (this.getFoundCities().length == 1) {
            window.location.href = this.getCityUrl(this.getFoundCities().pop().alias);
        }
    }

}
