import { ErrorHandler, InjectionToken } from "@angular/core";

export class BaseRouterErrorHandler implements ErrorHandler {
    public handleError(error: any): void {
        throw error;
    }
}

let routerErrorHandlers: ErrorHandler[] = [];

export function initRouterErrorHandler(handlers: ErrorHandler[]) {
    return () => {
        return new Promise<void>((resolve, reject) => {
            routerErrorHandlers = handlers;
            resolve();
        });
    };
}

export function routerErrorHandler(err: any) {
    for (let handler of routerErrorHandlers) {
        try {
            handler.handleError(err);
            break;
        } catch (e) {
            if (routerErrorHandlers.indexOf(handler) == routerErrorHandlers.length - 1) {
                throw err;
            }
        }
    }
}

export const ROUTER_ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ROUTER_ERROR_HANDLERS');
