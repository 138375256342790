import { Component, Injectable, OnInit } from "@angular/core";
import './vacancies.component.scss';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { BootstrapService } from "app/bootstrap/bootstrap.service";
import { HttpClient } from "@angular/common/http";
import { TransferStateService } from "app/bootstrap/transfer-state.service";
import { map } from "rxjs/operators";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Vacancy } from "./vacancy";

export class VacanciesData {
    @JsonProperty({name: 'company_description'})
    public companyDescription: string;

    @JsonProperty({type: Vacancy})
    public vacancies: Vacancy[];
}

@Injectable()
export class VacanciesResolver implements Resolve<VacanciesData> {
    protected readonly TRANSFER_STATE_KEY = 'VacanciesResolverData';

    public constructor(
        protected bootstrapService: BootstrapService,
        protected httpClient: HttpClient,
        protected transfaerStateService: TransferStateService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<VacanciesData> {
        return this.transfaerStateService.get<VacanciesData>(this.TRANSFER_STATE_KEY, () => {
            return this.httpClient.get<VacanciesData>('/v2/vacancies')
                .pipe(map(data => ObjectMapper.deserialize(VacanciesData, data)))
                .toPromise();
        });
    }

}

@Component({
    selector: 'vacancy-vacancies',
    templateUrl: './vacancies.component.html'
})
export class VacanciesComponent implements OnInit {
    public companyDescription: string;
    public vacancies: Vacancy[] = [];

    public constructor(
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.companyDescription = data.component.companyDescription;
            this.vacancies = data.component.vacancies;
        });
    }

}
