<div class="container pt-5">
    <div class="jumbotron bg-white mb-0">
        <h1>Что-то пошло не так :(</h1>
        <h2>404</h2>
        <p>Ой! Это ошибка 404. Это значит, что такой страницы на сайте не существует.</p>
        <div class="container">
            <div class="row">
                <div class="col-12 offset-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2 mt-5">
                    <city-search [focus]="true"></city-search>
                </div>
            </div>
            <div class="row">
                <div class="col-12 offset-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2 mt-3">
                    <popular-cities></popular-cities>
                </div>
            </div>
        </div>
    </div>
    <footer></footer>
</div>
