import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { FooterComponent } from "./footer/footer.component";
import { FooterService } from "./footer/footer.service";
import { CityCommonModule } from "city/city-common.module";

@NgModule({
    imports: [
        CommonModule,
        CityCommonModule,
        ReactiveFormsModule
    ],
    providers: [
        FooterService
    ],
    declarations: [
        FooterComponent
    ],
    exports: [
        FooterComponent
    ]
})
export class LayoutCommonModule {
}
