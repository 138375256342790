import { Injectable } from "@angular/core";
import { Router, Event as RouterEvent, ActivationEnd } from "@angular/router";
import { merge } from 'lodash';

export interface NavbarOptions {
    useAbsoluteLinks: boolean;
    phone?: string;
}

@Injectable()
export class NavbarService implements NavbarOptions {

    protected defaultOptions: NavbarOptions = {
        useAbsoluteLinks: false,
        phone: null
    };

    protected options: NavbarOptions = this.getDefaultOptions();

    public constructor(
        protected router: Router
    ) {
        this.init();
    }

    public init(): void {
        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof ActivationEnd) {
                this.setOptions(merge({}, this.getDefaultOptions(), event.snapshot.data.navbar));
            }
        });
    }

    public setDefaultOptions(options: NavbarOptions) {
        this.defaultOptions = options;
    }

    public getDefaultOptions(): NavbarOptions {
        return this.defaultOptions;
    }

    public setOptions(options: NavbarOptions): void {
        this.options = options;
    }

    public getOptions(): NavbarOptions {
        return this.options;
    }

    public get useAbsoluteLinks(): boolean {
        return this.options.useAbsoluteLinks;
    }

    public set useAbsoluteLinks(useAbsoluteLinks: boolean) {
        this.options.useAbsoluteLinks = useAbsoluteLinks;
    }

    public get phone(): string {
        return this.options.phone;
    }

    public set phone(phone: string) {
        this.options.phone = phone;
    }

}
