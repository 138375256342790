import { Inject, Injectable, Injector, NgZone, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Router, Event as  RouterEvent, NavigationEnd } from "@angular/router";

@Injectable()
export class AnchorLinkService {

    public constructor(
        @Inject(PLATFORM_ID) protected readonly platformId: Object,
        protected injector: Injector,
        protected ngZone: NgZone
    ) {
    }

    public init(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (isPlatformBrowser(this.platformId)) {
                let router: Router = this.injector.get(Router);
                router.events.subscribe((event: RouterEvent) => {
                    if (event instanceof NavigationEnd) {
                        let stableSubscriber = this.ngZone.onStable.subscribe(() => {
                            this.scrollIntoFragment(router.routerState.snapshot.root.fragment);
                            stableSubscriber.unsubscribe();
                        });
                    }
                });
            }
            resolve();
        });
    }

    protected scrollIntoFragment(fragment: string): void {
        if (!fragment || fragment.trim().length == 0) {
            return;
        }
        let fragmentExec = /^([-a-z\d_]+)/i.exec(fragment.trim());
        if (!fragmentExec) {
            return;
        }
        fragment = fragmentExec[0];
        let scrollTo = jQuery('[name="' + fragment + '"], #' + fragment).get(0);
        if (scrollTo) {
            scrollTo.scrollIntoView();
        }
    }

}
