import { Inject, Injectable } from "@angular/core";
import { makeStateKey, TransferState, ɵTRANSITION_ID as TRANSITION_ID } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";

@Injectable()
export class TransferStateService {

    protected counter: Map<string, number> = new Map<string, number>();

    public readonly isTransferStateEnabled: boolean = false;

    public constructor(
        protected transferState: TransferState,
        @Inject(TRANSITION_ID) protected transitionId: string,
        @Inject(DOCUMENT) protected document: HTMLDocument
    ) {
        let stateElement = document.getElementById(transitionId + '-state');
        // @todo: work on browser only
        this.isTransferStateEnabled = (stateElement && stateElement.tagName.toLowerCase() == 'script') ? true : false;
    }

    public get<T>(key: string, get: () => Promise<T>, limit: number = 1): Promise<T> {
        if (!this.counter.has(key)) {
            this.counter.set(key, 0);
        }
        this.counter.set(key, this.counter.get(key) + 1);
        if (limit > -1 && this.counter.get(key) - 1 >= limit) {
            return get();
        }
        let stateKey = makeStateKey<T>(key);
        if (this.transferState.hasKey<T>(stateKey)) {
            return new Promise<T>((resolve) => {
                resolve(this.transferState.get<T>(stateKey, null));
            });
        } else {
            return new Promise<T>((resolve, reject) => {
                get().then((data) => {
                    this.transferState.set<T>(stateKey, data);
                    resolve(data);
                }).catch((error) => {
                    this.transferState.remove(stateKey);
                    reject(error);
                });
            });
        }
    }

    public set<T>(key: string, value: T): void {
        this.transferState.set<T>(makeStateKey<T>(key), value);
    }

}
