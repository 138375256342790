import { Injectable, Injector } from "@angular/core";
import {
    Router,
    Event as RouterEvent,
    NavigationEnd,
    NavigationStart,
    ActivationEnd,
    NavigationError
} from "@angular/router";
import { ProgressbarService } from "./progressbar.service";

@Injectable()
export class ProgressbarRoutingService {

    protected router: Router;
    protected loaded: boolean = false;

    public constructor(
        protected injector: Injector,
        protected progressbarService: ProgressbarService
    ) {
    }

    public init(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.router = this.injector.get(Router);
            this.router.events.subscribe((event) => {
                if (!this.loaded) {
                    this.loaded = event instanceof NavigationEnd;
                } else {
                    this.onEvent(event);
                }
            });
            resolve();
        });
    }

    public onEvent(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.progressbarService.progress = 10;
        }
        if (event instanceof ActivationEnd) {
            this.progressbarService.progress = 100;
        }
        if (event instanceof NavigationEnd) {
            this.progressbarService.progress = 0;
        }
        if (event instanceof NavigationError) {
            this.progressbarService.progress = 0;
        }
    }

}
