import { ComponentFactoryResolver, Injector, NgModule } from "@angular/core";
import { Error404Component } from "./error404.component";
import { Error404RoutingModule } from "./error404-routing.module";
import { CityCommonModule } from "city/city-common.module";
import { LayoutCommonModule } from "layout/layout-common.module";
import { ROUTER_ERROR_HANDLERS } from "app/router/error-handler";
import { RouterErrorHandler } from "./router.error-handler";

@NgModule({
    imports: [
        Error404RoutingModule,
        LayoutCommonModule,
        CityCommonModule
    ],
    declarations: [Error404Component],
    providers: [
        {
            provide: ROUTER_ERROR_HANDLERS,
            useClass: RouterErrorHandler,
            multi: true,
            deps: [Injector, ComponentFactoryResolver]
        }
    ],
    exports: []
})
export class Error404Module {
}
