import { AccessType, JsonProperty } from "json-object-mapper";
import { DateSerializerDeserializer } from "common/date";
import { City2 } from "city/city";
import { Company } from "hostel/company";
import { Hotel } from "hostel/hotel";

export class Vacancy {

    @JsonProperty({name: 'vacancy_id', access: AccessType.READ_ONLY})
    public readonly vacancyId: number;

    @JsonProperty({
        name: 'creation_date',
        type: Date,
        serializer: DateSerializerDeserializer,
        deserializer: DateSerializerDeserializer,
        access: AccessType.READ_ONLY
    })
    public readonly creationDate: Date;

    @JsonProperty({
        name: 'update_date',
        type: Date,
        serializer: DateSerializerDeserializer,
        deserializer: DateSerializerDeserializer,
        access: AccessType.READ_ONLY
    })
    public readonly updateDate: Date;

    @JsonProperty({name: 'city', access: AccessType.READ_ONLY, type: City2})
    public readonly city: City2;

    @JsonProperty({name: 'title', access: AccessType.READ_ONLY})
    public readonly title: string;

    @JsonProperty({name: 'description', access: AccessType.READ_ONLY})
    public readonly description?: string;

    @JsonProperty({name: 'company', access: AccessType.READ_ONLY, type: Company})
    public readonly company: Company;

    @JsonProperty({name: 'hotel', access: AccessType.READ_ONLY, type: Hotel, required: false})
    public readonly hotel?: Hotel;

}

export class VacancyResponse {
    @JsonProperty()
    public name: string;

    @JsonProperty()
    public phone: string;

    @JsonProperty()
    public email: string;

    @JsonProperty({name: 'covering_letter'})
    public coveringLetter: string;
}
